import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import PageLayout from "./PageLayout";
import '../home.css';
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";
import LoadingError from "./LoadingError";


const Home = () => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_HOME);
                // Parse the string response into JSON
                const parsedData = JSON.parse(response.data);
                setData(parsedData[0]);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <LoadingError />;
    }
    return (

            <PageLayout pageTitle={data.title} sliderImages={data.img}>
                {data.cta && (
                    <Link to="/cta">
                        <div className='cta-container'>
                            <div className='cta-text'>
                                <h4>AUFGEPASST!</h4>
                                <p>{data.cta}</p>
                            </div>
                        </div>
                    </Link>
                )}
                <div className='home-overview container-fluid'>
                    <div className='container-fluid'>

                        <div className='overview-wrapper'>
                            <Link to="/activities" className="overview-activities" style={{ backgroundImage: `url(${data.activity_img})` }}>
                                <div className='overview-item'>
                                    <div className="dark-layer"></div>
                                    <h3
                                        className='overview-headline'>Urlaubsprogramm</h3>

                                </div>
                            </Link>
                            <Link to="/menu" className="overview-menu" style={{ backgroundImage: `url(${data.menu_img})` }}>
                                <div className='overview-item'>
                                    <div className="dark-layer"></div>
                                    <h3
                                        className='overview-headline'>Wirtshaus</h3>

                                </div>
                        </Link>
                            <Link to="/news" className="overview-events" style={{ backgroundImage: `url(${data.news_img})` }}>
                                <div className=' overview-item'>
                                    <div className="dark-layer"></div>
                                    <h3 className='overview-headline'>Aktuelle News &
                                        Veranstaltungen</h3>

                                </div>
                            </Link>
                            <Link to="/inclusive" className="overview-inclusive" style={{ backgroundImage: `url(${data.inclusive_img})` }}>
                                <div className='overview-inclusive overview-item'>
                                    <div className="dark-layer"></div>
                                    <h3 className='overview-headline'>Inklusivleistungen</h3>

                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='home-nav'>
                        <Link to="/voucher" ><img src='/img/voucher.svg' alt=''/>
                        </Link>
                        <Link to="/socialwall" ><img src='/img/social.svg' alt=''/>
                        </Link>
                        <Link to="/virtualtour" ><img src='/img/360.svg' alt=''/>
                        </Link>
                    </div>

                </div>


            </PageLayout>


    );
}

export default Home;

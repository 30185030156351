import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HeaderSlider = ({ pageTitle, subHeader, images }) => {
    const settings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        fade:true
    };
    const imageArray = Array.isArray(images) ? images : [images];

    return (
        <div className='header-container'>
            <div className='header-text'>
                <h2>{pageTitle}</h2>
                {subHeader && <p className="sub-header">{subHeader}</p>}
            </div>

            <img src='/img/logo.svg' alt='Logo' className='logo'/>
            <div className="dark-layer-header"></div>
            <div className="header-slider">
                <Slider {...settings}>
                    {imageArray.map((image, index) => (
                        <div key={index}>

                            <img src={image} alt={`Slide ${index + 1}`} />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default HeaderSlider;
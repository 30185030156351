import React from 'react';
import QRCode from 'qrcode.react';
import '../qr.css';

const CircularQRCode = ({ data, }) => {
    return (
        <div className='qr-container'>

            <div className="circular-qr-code">
                <div>
                <QRCode value={data} size={180} className="qr-code"/>
                </div>

            </div>
            <p>
                Alle Infos auch am Handy!
                <img src='/img/qr-arrow.svg' alt=''/>
            </p>
            <p>Einfach QR-Code scannen.</p>
        </div>
    );
};

export default CircularQRCode;
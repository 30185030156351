import React from 'react';


const LoadingError = () => {


function navigateToStart(){
   fetch(process.env.REACT_APP_API_STATUS).then(response=>window.location.href = '/').catch(error=>console.log(error));

};

    return (

            <div className='error-page'>
                <h2>Die Zielseite kann momentan nicht geladen werden</h2>


                <button className='link-btn' onClick={navigateToStart}>
                    Zurück zur Startseite
                </button>
            </div>

    );
}

export default LoadingError;
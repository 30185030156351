import React, { useEffect, useState } from 'react';
import { fetchSubpages } from "./api";
import PageLayout from "./PageLayout";
import { Link } from "react-router-dom";
import CircularQRCode from "./CircularQRCode";
import BadgeQRCode from "./BadgeQRCode";
import GallerySlider from "./GallerySlider";
import LoadingSpinner from "./LoadingSpinner";
import LoadingError from "./LoadingError";

const Inclusive = () => {
    const [pageData, setPageData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch subpages data when the component mounts
        fetchSubpages()
            .then((data) => {

                const jsonData = JSON.parse(data)
                if (Array.isArray(jsonData)) {

                    const pageSubpage = jsonData.find((subpage) => subpage.subpage === "incl");
                    if (pageSubpage) {
                        setPageData(pageSubpage);
                        setIsLoading(false);
                    } else {
                        console.error('Error fetching data:', error);
                        setError(error);
                        setIsLoading(false);
                    }
                } else {
                    console.error('Error fetching data:', error);
                    setError(error);
                    setIsLoading(false);
                }
            })
            .catch((error) => {console.error('Error fetching data:', error)
            setError(error)
            setIsLoading(false)});

    },[] );

    const renderHTML = (htmlString) => {
        return { __html: htmlString };
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <LoadingError />;
    }

    return (
        <PageLayout pageTitle={pageData.title} subHeader={pageData.sub_title} sliderImages={pageData.img}>
            <Link to="/"><span
                className='back-btn'><img src='/img/arrow.svg' alt=''/> &nbsp; Zurück</span>
            </Link>
            <Link to="/"><span
                className='home-btn'><img src='/img/home-line.svg' alt=''/> &nbsp;Home</span>
            </Link>
            {pageData.h2 &&(
                <h2 className='headline-underline'>{pageData.h2}</h2>
            )}
            <div className={pageData.link_2 && pageData.link_2_text ? 'text-batch-wrapper space-bottom' : 'text-wrapper space-bottom'}>
                <div dangerouslySetInnerHTML={renderHTML(pageData.description)} className='rte-text'></div>
                {pageData.link_2 && pageData.link_2_text && (
                    <div className='badge'>
                        <div className='badge-text'>{pageData.link_2_text}</div>
                        <BadgeQRCode data={pageData.link_2}/>
                    </div>
                )}



            </div>
            {pageData.gallery &&(
                <GallerySlider images={pageData.gallery} />
            ) }
            <CircularQRCode data={pageData.link} />
        </PageLayout>
    );
};

export default Inclusive;
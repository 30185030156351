import React from 'react';
import QRCode from 'qrcode.react';


const BadgeQRCode = ({ data, }) => {
    return (
        <div className='badge-qr-container'>


            <div className="badge-qr-code">
                <div className='main-badge-qr'>
                    <QRCode value={data} size={230} className="qr-code"/></div>

                <img src='/img/qr-arrow.svg' alt=''/>
                <span>Scanne mich!</span>
            </div>




        </div>
    );
};

export default BadgeQRCode;
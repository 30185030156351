import React from 'react';
import { Route, Routes} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from "./components/Home";
import ApiNews from "./components/ApiNews";
import ApiActivities from "./components/ApiActivities";
import NewsDetails from "./components/NewsDetails";
import ActivityDetails from "./components/ActivityDetails";
import SocialWall from "./components/SocialWall";
import Voucher from "./components/Voucher";
import VirtualTour from "./components/VirtualTour";
import Inclusive from "./components/Inclusive";
import Menu from "./components/Menu";
import Cta from "./components/Cta";

function App() {
    return (

            <div>
                <Routes>

                    <Route path="/" element={<Home />} />
                    <Route path="/inclusive" element={<Inclusive />} />
                    <Route path="/menu" element={<Menu />} />
                    <Route path="/news/*" element={<ApiNews />} />
                    <Route path="/news/:itemId" element={<NewsDetails />} />
                    <Route path="/activities/*" element={<ApiActivities />} />
                    <Route path="/activities/:itemId" element={<ActivityDetails/>} />
                    <Route path="/socialwall" element={<SocialWall/>} />
                    <Route path="/voucher" element={<Voucher/>} />
                    <Route path="/virtualtour" element={<VirtualTour/>} />
                    <Route path="/cta" element={<Cta/>} />
                </Routes>

            </div>


    );
}

export default App;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'; // Import necessary modules
import PageLayout from './PageLayout';
import CircularQRCode from "./CircularQRCode";
import '../news.css';
import LoadingSpinner from "./LoadingSpinner";
import LoadingError from "./LoadingError";

const ApiNews = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState(null); // Add selected item state
    const itemsPerPage = 10;
    const navigate = useNavigate(); // Get the navigate function from React Router

    const extractImageUrls = (items) => {
        return items.map(item => item.image);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_NEWS);
                // Parse the string response into JSON
                const parsedData = JSON.parse(response.data);
                setData(parsedData);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Handle item click
    const handleItemClick = (itemId) => {
        // Find the selected item based on the item ID
        const selectedItem = data.find((item) => item.id === itemId);
        if (selectedItem) {
            setSelectedItem(selectedItem);
            navigate(`/news/${itemId}`, { state: { itemData: selectedItem } }); // Pass data as state
        }
    };

    const renderHTML = (htmlString) => {
        return { __html: htmlString };
    };

    const imageUrls = extractImageUrls(currentItems);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <LoadingError />;
    }

    return (
        <PageLayout pageTitle="Aktuelle News & Veranstaltungen" subHeader='Dein Urlaub in St.Veit im Pongau!' sliderImages={imageUrls}>
            <div>

                <Link to='/'>
                    <span className='back-btn'><img src='/img/arrow.svg' alt=''/> &nbsp; Zurück</span>
                </Link>
                <Link to="/"><span
                    className='home-btn'><img src='/img/home-line.svg' alt=''/> &nbsp;Home</span>
                </Link>

                <h2 className='headline-underline'>Was gibt's Neues</h2>
                <p>Hier erfahren Sie die aktuellsten News des Hauses.
                    Wir informieren über die schönsten Ausflugstipps, die besten Angebote und tolle Veranstaltungen im und rund um den Metzgerwirt in St. Veit im Pongau.</p>
                <div className='events container-fluid'>
                    <div className='row justify-content-center'>
                        {currentItems.map((item) => (
                            <div
                                className='event col-12 m-3'
                                key={item.id}
                                onClick={() => handleItemClick(item.id)}
                                style={{ cursor: 'pointer' }}
                            >
                                <div className='event-thumb'>
                                    <img src={item.thumb} alt=''/>
                                </div>
                                <div className='event-teaser'>
                                    <h4>{item.title}</h4>
                                    <p>{item.teaser}</p>
                                    <div dangerouslySetInnerHTML={renderHTML(item.description)} className='event-short-description'></div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <CircularQRCode data='https://hotel-metzgerwirt.com/de/aktuelles'/>

            </div>

        </PageLayout>
    );
};

export default ApiNews;

import React from 'react';
import HeaderSlider from './HeaderSlider';
import Footer from './Footer';

const PageLayout = ({ children, pageTitle, subHeader, sliderImages }) => {
    return (
        <div>
            <HeaderSlider pageTitle={pageTitle} subHeader={subHeader} images={sliderImages} />
            <div className='content-wrapper'>
                {children}
            </div>
            <Footer />
        </div>
    );
}

export default PageLayout;
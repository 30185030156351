import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import PageLayout from "./PageLayout";
import CircularQRCode from "./CircularQRCode";
import GallerySlider from "./GallerySlider";
import LoadingSpinner from "./LoadingSpinner";

const NewsDetails = () => {
    const location = useLocation();
    const itemData = location.state?.itemData || null;

    if (!itemData) {
        return <LoadingSpinner/>;
    }
    const renderHTML = (htmlString) => {
        return { __html: htmlString };
    };

    return (
        <div>
            <PageLayout pageTitle={itemData.title} subHeader={itemData.teaser} sliderImages={itemData.image}>
                <Link to="/news"><span
                    className='back-btn'><img src='/img/arrow.svg' alt=''/> &nbsp; Zurück</span>
                </Link>
                <Link to="/"><span
                    className='home-btn'><img src='/img/home-line.svg' alt=''/> &nbsp;Home</span>
                </Link>
                <div className='event-detail'>
                    <div className='event-detail-text'>
                        <div dangerouslySetInnerHTML={renderHTML(itemData.description)}></div>
                    </div>
                    <div className='event-thumb'><img src={itemData.thumb} alt=''/></div>
                </div>

                {itemData.gallery ? (
                    <GallerySlider images={itemData.gallery} />
                ) : (
                    console.log('no gallery')
                )}
                <CircularQRCode data='https://hotel-metzgerwirt.com/aktuelles'/>
            </PageLayout>
        </div>
    );
};

export default NewsDetails;
import React from 'react';

const LoadingSpinner = () => {
    return (
        <div className='loading-spinner'>

            <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div>Inhalt wird geladen...</div>
            <div><img src='/img/logo.svg' alt='logo' width='100%'/> </div>
        </div>
    );
}

export default LoadingSpinner;
import axios from 'axios';


export const fetchSubpages = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_SUBPAGES);
        // console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching subpages:', error);
        return [];
    }
};
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



const MenuSlider = ({ images }) => {
    const settings = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        dots:true,
        // centerMode:true,


    };
    const imageArray = Array.isArray(images) ? images : [images];

    return (

            <div className="menu-slider">
                <Slider {...settings}>
                    {imageArray.map((image, index) => (
                        <div key={index}>

                            <img src={image} className='menu-page' alt={`Slide ${index + 1}`} />
                        </div>
                    ))}
                </Slider>
            </div>
    );
};

export default MenuSlider;
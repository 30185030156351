import React, { useEffect, useState } from 'react';
import { fetchSubpages } from "./api";
import PageLayout from "./PageLayout";
import { Link } from "react-router-dom";
import CircularQRCode from "./CircularQRCode";
import BadgeQRCode from "./BadgeQRCode";
import GallerySlider from "./GallerySlider";
import '../virtual-tour.css';
import LoadingSpinner from "./LoadingSpinner";
import LoadingError from "./LoadingError";

const VirtualTour = () => {
    const [pageData, setPageData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        fetchSubpages()
            .then((data) => {

                const jsonData = JSON.parse(data)
                if (Array.isArray(jsonData)) {

                    const pageSubpage = jsonData.find((subpage) => subpage.subpage === "360");
                    if (pageSubpage) {
                        setPageData(pageSubpage);
                        setIsLoading(false);
                    } else {

                        console.error('Error fetching data:', error);
                        setError(error);
                        setIsLoading(false);
                    }
                } else {
                    console.error('Error fetching data:', error);
                    setError(error);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setError(error);
                setIsLoading(false);
            });
    }, []);

    const renderHTML = (htmlString) => {
        return { __html: htmlString };
    };

    useEffect(() => {
        // Function to remove YouTube overlay elements
        const removeYouTubeOverlayElements = () => {
            const elementsToRemove = [
                document.querySelector('.ytp-pause-overlay.ytp-scroll-min'),
                document.querySelector('.ytp-pause-overlay'),
                document.querySelector('.ytp-pause-overlay-container'),
            ];

            elementsToRemove.forEach((element) => {
                if (element) {
                    element.remove();
                }
            });
        };

        // Set a timeout to run the removal function after 1000 milliseconds (1 second)
        const timeoutId = setTimeout(removeYouTubeOverlayElements, 1000);

        // Clean up the timeout when the component unmounts
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);


    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <LoadingError />;
    }


    return (
        <PageLayout pageTitle={pageData.title} subHeader={pageData.sub_title} sliderImages={pageData.img}>
            <Link to="/"><span
                className='back-btn'><img src='/img/arrow.svg' alt=''/> &nbsp; Zurück</span>
            </Link>
            <Link to="/"><span
                className='home-btn'><img src='/img/home-line.svg' alt=''/> &nbsp;Home</span>
            </Link>
            {pageData.h2 &&(
                <h2 className='headline-underline'>{pageData.h2}</h2>
            )}
            <div className={pageData.link_2 && pageData.link_2_text ? 'text-batch-wrapper space-bottom' : 'text-wrapper space-bottom'}>
                <div dangerouslySetInnerHTML={renderHTML(pageData.description)} className='rte-text'></div>
                {pageData.link_2 && pageData.link_2_text && (
                    <div className='badge'>
                        <div className='badge-text'>{pageData.link_2_text}</div>
                        <BadgeQRCode data={pageData.link_2}/>
                    </div>
                )}



            </div>
            <div className='home-video space-bottom'>
                    <div className="video-container">
                        <iframe
                            id="videoIframe"
                            width="100%"
                            height="auto"
                            src="https://www.youtube.com/embed/JiIdeGgvGTY?si=ICZ3nfhaoaNGIYwi&autoplay=1&loop=1&mute=1&ecver=2&controls=0&showinfo=0&modestbranding=1&rel=0&iv_load_policy=3&disablekb=1"
                            title="YouTube video player"
                            frameBorder="0"
                            allowFullScreen
                            sandbox="allow-same-origin allow-scripts"
                        ></iframe>
                    </div>
            </div>
            {pageData.gallery &&(
                <GallerySlider images={pageData.gallery} />
            ) }
            <CircularQRCode data={pageData.link} />
        </PageLayout>
    );
};

export default VirtualTour;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PageLayout from "./PageLayout";
import { Link, useNavigate } from 'react-router-dom';
import CircularQRCode from "./CircularQRCode";
import '../activity.css';
import LoadingSpinner from "./LoadingSpinner";
import LoadingError from "./LoadingError";

const extractImageUrls = (items) => {
    return items.map(item => item.image);
};

const ApiActivities = () => {
    const [data, setData] = useState([]);
    const [currentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null); // Add selected item state
    const itemsPerPage = 6;
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_ACTIVITIES);
                // Parse the string response into JSON
                const parsedData = JSON.parse(response.data);
                setData(parsedData);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <LoadingError />;
    }
    const handleItemClick = (itemId) => {
        // Find the selected item based on the item ID
        const selectedItem = data.find((item) => item.id === itemId);
        if (selectedItem) {
            setSelectedItem(selectedItem);
            navigate(`/activities/${itemId}`, { state: { itemData: selectedItem } }); // Pass data as state
        }
    };


    const imageUrls = extractImageUrls(currentItems);

    return (
        <PageLayout pageTitle="Urlaubsprogramm" subHeader='Dein Urlaub in St.Veit im Pongau!' sliderImages={imageUrls}>

            <div>
                <Link to="/"><span
                    className='back-btn'><img src='/img/arrow.svg' alt=''/> &nbsp; Zurück</span>
                </Link>
                <Link to="/"><span
                    className='home-btn'><img src='/img/home-line.svg' alt=''/> &nbsp;Home</span>
                </Link>
                <h2 className='headline-underline'>Aktivitäten</h2>
                <div className="activities container-fluid">
                    <div className="row justify-content-between">
                        {currentItems.map((item) => (
                            <div
                                className='activity '
                                key={item.id}
                                onClick={() => handleItemClick(item.id)}
                                style={{ cursor: 'pointer', backgroundImage: `url(${item.thumb})` }}
                            >
                                <div className='dark-layer-thumb'></div>

                                <h4>{item.name}</h4>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <CircularQRCode data='https://hotel-metzgerwirt.com/de/urlaubsprogramm/'/>

        </PageLayout>
    );
};

export default ApiActivities;
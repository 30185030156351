import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';


const root = document.getElementById('root');

const createRoot = ReactDOM.createRoot(root);

createRoot.render(
    <Router>
            <Routes>

                <Route path="*" element={<App />} />
            </Routes>
    </Router>
);

import React from 'react';

const Footer = () => {
    return (
        <div className='footer-container'>
            <div className='footer'>
                <span>&copy; Hotel Metzgerwirt | v 1.0.0</span>
                <span>created by <img src='/img/obinet.svg' alt='logo obinet' className='o-logo'/></span>
            </div>
            <div className='footer-logo'><img src='/img/logo.svg' alt='logo' width='100%' /> </div>

        </div>
    );
}

export default Footer;
import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import PageLayout from "./PageLayout";
import CircularQRCode from "./CircularQRCode";
import GallerySlider from "./GallerySlider";
import LoadingSpinner from "./LoadingSpinner";
import BadgeQRCode from "./BadgeQRCode";


const ActivityDetails = () => {
    const location = useLocation();
    const itemData = location.state?.itemData || null;

    if (!itemData) {
        // Handle the case when data is not available
        return <LoadingSpinner/>;
    }

    const renderHTML = (htmlString) => {
        return { __html: htmlString };
    };

    return (
        <div>
            <PageLayout pageTitle={itemData.name} subHeader='Dein Urlaub in St.Veit im Pongau!' sliderImages={itemData.image}>
                <Link to="/activities"><span
                    className='back-btn'><img src='/img/arrow.svg' alt=''/> &nbsp; Zurück</span>
                </Link>
                <Link to="/"><span
                    className='home-btn'><img src='/img/home-line.svg' alt=''/> &nbsp;Home</span>
                </Link>
                {itemData.title && (
                    <h2 className='headline-underline'>{itemData.title}</h2>
                )}
                <div className={itemData.link_2 && itemData.link_2_text ? 'text-batch-wrapper space-bottom' : 'text-wrapper space-bottom'}>
                    <div dangerouslySetInnerHTML={renderHTML(itemData.description)} className='rte-text'></div>
                    {itemData.link_2 && itemData.link_2_text && (
                        <div className='badge'>
                            <div className='badge-text'>{itemData.link_2_text}</div>
                            <BadgeQRCode data={itemData.link_2}/>
                        </div>
                    )}



                </div>

                {itemData.gallery ? (
                    <GallerySlider images={itemData.gallery} />
                ) : (
                    console.log('no gallery')
                )}
                <p className='link-info-text'>{itemData.link_text}</p>
                <CircularQRCode data={itemData.link}/>

            </PageLayout>
        </div>

    );
};

export default ActivityDetails;